<template>
  <span>
    <transition name="fade">
      <settings-warning v-if="hasChange" style="animation-duration: 0.5s" />
    </transition>
    <div class="store-options">
      <hcc-card
        :title="$t('configuration.store-options.title')"
        color="gray"
        class="card-body border"
      >
        <config-card
          :title="$t('configuration.store-options.card-payments.title')"
          :key="`${this.campaign.id}11`"
          :text="$t('configuration.store-options.card-payments.description')"
          v-model="optionModal.cardPayments"
          @change="hasChanged"
          name="cardPayments"
        />
        <hcc-card v-if="optionModal.cardPayments" class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.conekta-publickey.title") }}
              </p>
              <hcc-input
                type="text"
                size="sm"
                placeholder="Public-key"
                v-model.number="optionModal.conektaPublicKey"
                @change="hasChanged"
                name="conektaPublicKey"
                class="input"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.conekta-publickey.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card v-if="optionModal.cardPayments" class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.conekta-secretkey.title") }}
              </p>
              <hcc-input
                type="text"
                size="sm"
                placeholder="Secret-key"
                v-model.number="optionModal.conektaSecretKey"
                @change="hasChanged"
                name="conektaSecretKey"
                class="input"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.conekta-secretkey.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <config-card
          :title="$t('configuration.store-options.cash-payments.title')"
          :key="`${this.campaign.id}12`"
          :text="$t('configuration.store-options.cash-payments.description')"
          v-model="optionModal.cashPayments"
          @change="hasChanged"
          name="cashPayments"
        />
        <config-card
          :title="$t('configuration.store-options.show-qr.title')"
          :key="`${this.campaign.id}13`"
          :text="$t('configuration.store-options.show-qr.description')"
          v-model="optionModal.showQR"
          @change="hasChanged"
          name="showQR"
        />
        <config-card
          :title="$t('configuration.store-options.stock-alert.title')"
          :key="`${this.campaign.id}14`"
          :text="$t('configuration.store-options.stock-alert.description')"
          v-model="optionModal.stockAlert"
          @change="hasChanged"
          name="stockAlert"
        />
        <hcc-card v-if="optionModal.stockAlert" class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.min-stock.title") }}
              </p>
              <hcc-input
                type="number"
                size="sm"
                min="0"
                placeholder="0"
                v-model.number="optionModal.minStock"
                @change="hasChanged"
                name="minStock"
                class="input"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.min-stock.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-button
          type="submit"
          size="md"
          class="submit"
          :disabled="!hasChange || isSaving"
          @click="save()"
          >{{ $t("common.save") }}
        </hcc-button>
      </hcc-card>
    </div>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SettingsWarning from './SettingsWarning.vue';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    ConfigCard: () => import('@/components/settings/ConfigCard.vue'),
    SettingsWarning,
  },
  data() {
    return {
      options: null,
      isSaving: false,
      hasChange: false,
      optionModal: {
        cardPayments: false,
        cashPayments: false,
        showQR: false,
        stockAlert: false,
        minStock: 0,
        conektaPublicKey: null,
        conektaSecretKey: null,
      },
      originalData: {},
      categories: [],
      messages: [],
    };
  },
  computed: {
    ...mapState({
      selectedStore: state => state.shop.item,
    }),
    updatedData() {
      return Object.values(this.optionModal).join();
    },
  },
  async mounted() {
    await this.getSelectedStore(this.$route.params.campaign_id);
    await this.getStoreSettings();
  },
  methods: {
    ...mapActions({
      getSelectedStore: 'shop/getById',
      updateStore: 'shop/update',
    }),
    hasChanged() {
      this.hasChange = this.updatedData !== this.originalData;
    },
    async getStoreSettings() {
      if (this.selectedStore) {
        const shop = this.selectedStore;
        this.optionModal.cardPayments = shop.settings.cardPayments || false;
        this.optionModal.cashPayments = shop.settings.cashPayments || false;
        this.optionModal.showQR = shop.settings.showQr || false;
        this.optionModal.stockAlert = shop.settings.stockAlert || false;
        this.optionModal.minStock = (this.optionModal.stockAlert) ? shop.settings.minStock : 0;
        this.optionModal.conektaPublicKey = shop.settings.conektaPublicKey || null;
        this.optionModal.conektaSecretKey = shop.settings.conektaSecretKey || null;
      }
      this.originalData = Object.values(this.optionModal).join();
    },
    async save() {
      this.isSaving = true;

      if (this.selectedStore) {
        const { id } = this.selectedStore;
        const settings = this.optionModal;
        await this.updateStore({ id, settings });
        this.hasChange = false;
        this.$toasted.global.success(this.$t('configuration.options.updated'));
        this.originalData = Object.values(this.optionModal).join();
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_store-options-tab.scss";
</style>
